<!--表单页面拷贝开始-->
<template>
  <div>
    <el-form :model="tripMotorCade" :rules="rules" ref="form" label-width="85px">
      <el-form-item label="运营公司:" prop="companyNo">
        <el-col :span="8">
          <div v-if="user.type == 'admin'">
            <el-select v-model="tripMotorCade.companyNo" placeholder="请选择运营公司" @change="change">
              <el-option v-for="item in CompanyList" :key="item.no" :label="item.name" :value="item.no">
              </el-option>
            </el-select>
          </div>
          <div v-else>
            {{ user.companyName }}
          </div>
        </el-col>
      </el-form-item>

      <el-form-item label="车队名称" prop="name" style="">
        <el-col :span="8">
          <el-input v-model.trim="tripMotorCade.name" maxlength="40" placeholder="请输入车队名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="负责人" prop="leaderName">
        <el-col :span="8">
          <el-input v-model.trim="tripMotorCade.leaderName" maxlength="40" placeholder="请输入负责人"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="联系电话" prop="mobile">
        <el-col :span="8">
          <el-input v-model.trim="tripMotorCade.mobile" maxlength="12" placeholder="请输入联系电话"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <div style="text-align: center; margin-top: 50px">
          <el-button type="primary" @click="submitForm">保存</el-button>
          <el-button @click="cancelForm">取消</el-button>
        </div>
      </el-form-item>
    </el-form>

    <!-- <form-components
      @submitForm="submitForm"
      @cancelForm="cancelForm"
      :formData="tripMotorCade"
      :rules="rules"
    >
      <template v-slot:content>
        <el-form-item label="运营公司:">
          <el-col :span="8">
            <div v-if="user.type == 'admin'">
              <el-select
                v-model="companyNo"
                placeholder="请选择运营公司"
                @change="change"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.no"
                  :label="item.name"
                  :value="item.no"
                >
                </el-option>
              </el-select>
            </div>
            <div v-else>
              {{ user.companyName }}
            </div>
          </el-col>
        </el-form-item>

        <el-form-item label="车队名称" prop="name" style="">
          <el-col :span="8">
            <el-input
              v-model="tripMotorCade.name"
              maxlength="40"
              placeholder="请输入车队名称"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="负责人" prop="leaderName">
          <el-col :span="8">
            <el-input
              v-model="tripMotorCade.leaderName"
              maxlength="40"
              placeholder="请输入负责人"
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="联系电话" prop="mobile">
          <el-col :span="8">
            <el-input
              v-model="tripMotorCade.mobile"
              maxlength="11"
              placeholder="请输入联系电话"
            ></el-input>
          </el-col>
        </el-form-item>
      </template>
    </form-components> -->
  </div>
</template>
  
  <script>
import FormComponents from "@/components/form/FormComponents";
import TripMotorCadeApi from "@/api/TripMotorCadeApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
import SysUserApi from "@/api/SysUserApi";
import { mapMutations } from "vuex";
export default {
  name: "SaveTripMotorCadePage",
  props: {
    msg: String,
  },
  components: {
    FormComponents,
  },
  data() {
    return {
      CompanyList: [],
      tripMotorCade: {
        name: "",
        leaderName: "",
        mobile: "",
        companyNo: "",
        companyName: "",
      },
      rules: {
        companyNo: [
          { required: true, message: "请选择运营公司", trigger: "change" },
        ],
        name: [
          { required: true, message: "车队名称不能为空", trigger: "blur" },
          { min: 3, max: 40, message: "长度应在3-40个字符", trigger: "blur" },
        ],
        leaderName: [
          { required: true, message: "负责人不能为空", trigger: "blur" },
          { min: 2, max: 40, message: "长度应在2-40个字符", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          { min: 7, max: 12, message: "联系电话长度应在7-12个字符", trigger: "blur" },
          // {
          //   pattern: /^1[3-9]\d{9}$/,
          //   message: "联系电话格式错误",
          //   trigger: "blur",
          // },
        ],
      },
      companyNo: "",
      user: this.$store.state.user,
    };
  },
  created() {
    if (this.$route.params) {
      this.tripMotorCade = this.$route.params;
      this.companyNo = this.tripMotorCade.companyNo;
    }
    if (this.user.type != "admin") {
      this.tripMotorCade.companyNo = this.user.companyNo;
      this.tripMotorCade.companyName = this.user.companyName;
    }
    this.getCompanyList();
  },
  methods: {
    ...mapMutations({
      close: "mutationCloseTag",
    }),
    getCompanyList() {
      SysUserApi.findCompanySelect().then((resp) => {
        if (resp.code == "200") {
          this.CompanyList = resp.data;
        }
      });
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.tripMotorCade.id > 0
          ? this.updateTripMotorCadeById()
          : this.addTripMotorCade();
      });
    },
    addTripMotorCade() {
      if (this.user.type != "admin") {
        this.tripMotorCade.companyNo = this.user.companyNo;
      }
      this.tripMotorCade.companyName = this.CompanyList.filter((p) => {
        return p.no.toString() == this.tripMotorCade.companyNo.toString();
      })[0].name;
      TripMotorCadeApi.saveTripMotorCade(this.tripMotorCade).then((resp) => {
        if (resp.code === '200') {
          this.tripMotorCade = {};
          this.$successMsg(resp.msg);
          this.cancelForm();
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    updateTripMotorCadeById() {
      if (this.user.type != "admin") {
        this.tripMotorCade.companyNo = this.user.companyNo;
      }
      this.tripMotorCade.companyName = this.CompanyList.filter((p) => {
        return p.no.toString() == this.tripMotorCade.companyNo.toString();
      })[0].name;
      TripMotorCadeApi.updateTripMotorCade(this.tripMotorCade).then((resp) => {
        if (resp.code === '200') {
          this.user = this.$store.state.user;
          this.$successMsg(resp.msg);
          this.cancelForm();
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    change(val) {
      this.tripMotorCade.companyNo = val;
    },
    cancelForm() {
      this.close({
        path: "/saveTripMotorCade",
      });
      this.$router.push("/tripMotorCadeList");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
</style>
  
  <!--表单页面拷贝结束-->